
$(document).ready(function () {
    let ajaxLocked = false;

    let modalParentElementInDOM = $('div#main-container');
    let modalTitle = '';
    let modalMessage = '';
    let modalButtonText = 'Lukk';
    let modalAlertType = '';
    let menuWrapper = $('div#category_menu_list_wrapper');
    let menuHeight = menuWrapper.height();

    //Check if new menu exists
    if ($('nav.category-menu-list').length) {

        let menuOffsetTop = menuWrapper.offset().top;
        let navbarElement = $('header.navbar-fixed-top');
        //let navbarHeight = navbarElement.height;
        let navbarHeight = navbarElement.css('position') === 'relative' ? 0 : navbarElement.height();
        let scrollWindow = $('div#crumbs_holder');
        let scrollContent = $('div#crumbs_holder ul');
        let subScrollWindow = $('div#sub_crumbs_holder');
        let subScrollContent = $('div#sub_crumbs_holder ul');

        //Stick menu to the top of site if user scrolls below it
        $(window).scroll(function () {
            if ($(window).scrollTop() > menuOffsetTop - navbarHeight) {
                toggleFixed();
            }

            if ($(window).scrollTop() < menuOffsetTop - navbarHeight) {
                removeFixed();
            }
        });


        //Adjust width of menu if window is resized
        $(window).resize(function () {
            adjustFixedWidth();

            //Hide buttons and arrows if window is bigger than the inner (scrolling) div
            if (scrollContent.width() <= scrollWindow.outerWidth()) {
                scrollWindow.removeClass('grab');
                hideScrollButtons();
            }

            //Show buttons and arrows if window is bigger than the inner (scrolling) div
            if (scrollContent.width() > scrollWindow.outerWidth()) {
                scrollWindow.addClass('grab');
                showScrollButtons();

                //Scroll is not all left
                if (scrollWindow.scrollLeft() > 0) {
                    arrowInButton('left', 'pointer', false);
                }

                //Scroll is not all to the right
                if (scrollWindow.scrollLeft() < scrollContent.get(0).scrollWidth - scrollWindow.outerWidth()) {
                    arrowInButton('right', 'pointer', false);
                }
            }

            //(Sub menu) Hide arrows if window is bigger than the inner (scrolling) div
            if (subScrollContent.width() <= subScrollWindow.outerWidth()) {
                subScrollWindow.removeClass('grab');
                hideScrollButtons(true);
            }

            //(Sub menu) Show buttons and arrows if window is bigger than the inner (scrolling) div
            if (subScrollContent.width() > subScrollWindow.outerWidth()) {
                subScrollWindow.addClass('grab');
                showScrollButtons(true);

                //Scroll is not all left
                if (subScrollWindow.scrollLeft() > 0) {
                    arrowInButton('left', 'pointer', false, true);
                }

                //Scroll is not all to the right
                if (subScrollWindow.scrollLeft() < subScrollContent.get(0).scrollWidth - subScrollWindow.outerWidth()) {
                    arrowInButton('right', 'pointer', false, true);
                }
            }

            showScrollWithInternetExplorer();
        });


        // Main menu ---------------------

        //Scroll to active category
        if ($('div#crumbs_holder li.active').length) {
            let scrollToElement = $('div#crumbs_holder li.active');
            scrollToActive(scrollWindow, scrollToElement, scrollContent);
        }

        //Show add cursor:grab if window is bigger than the inner (scrolling) div
        if (scrollContent.width() > scrollWindow.outerWidth()) {
            scrollWindow.addClass('grab');
        }

        //Hide the two scrolling buttons if window is bigger than the inner (scrolling) div
        if (scrollContent.width() <= scrollWindow.outerWidth()) {
            scrollWindow.removeClass('grab');
            hideScrollButtons();
        }

        //Scroll is all to left
        if (scrollWindow.scrollLeft() === 0) {
            arrowInButton('left', 'default', true);
        }

        //Scroll is all to right (15px is added because you cant get all to the right when you scroll with finger on mobile)
        if (scrollWindow.scrollLeft() > scrollContent.get(0).scrollWidth - scrollWindow.outerWidth() - 15) {
            arrowInButton('right', 'default', true);
        }

        //Scroll on main categories is activated
        $("div#crumbs_holder").on('scroll', function () {

            //Scroll is all to the left
            if (scrollWindow.scrollLeft() === 0) {
                arrowInButton('left', 'default', true);
            }

            //Scroll is not all left
            if (scrollWindow.scrollLeft() > 0) {
                arrowInButton('left', 'pointer', false);
            }

            //Scroll is all to the right (15px is added because you cant get all to the right when you scroll with finger on mobile)
            if (scrollWindow.scrollLeft() > scrollContent.get(0).scrollWidth - scrollWindow.outerWidth() - 15) {
                arrowInButton('right', 'default', true);
            }

            //Scroll is not all to the right (15px is added because you cant get all to the right when you scroll with finger on mobile)
            if (scrollWindow.scrollLeft() < scrollContent.get(0).scrollWidth - scrollWindow.outerWidth() - 15) {
                arrowInButton('right', 'pointer', false);
            }
        });

        //Sub category menu --------------
        //Check if sub menu exists
        if ($('nav.sub-category-menu-list').length) {

            //Show buttons and arrows if window is bigger than the inner (scrolling) div
            if (subScrollContent.width() > subScrollWindow.outerWidth()) {
                subScrollWindow.addClass('grab');
            }

            //Hide arrows if window is bigger than the inner (scrolling) div
            if (subScrollContent.width() <= subScrollWindow.outerWidth()) {
                subScrollWindow.removeClass('grab');
                hideScrollButtons(true)
            }

            //Scroll is all to left
            if (subScrollWindow.scrollLeft() === 0) {
                arrowInButton('left', 'default', true, true);
            }

            //Scroll is all to right (15px is added because you cant get all to the right when you scroll with finger on mobile)
            if (subScrollWindow.scrollLeft() > subScrollContent.get(0).scrollWidth - subScrollWindow.outerWidth() - 15) {
                arrowInButton('right', 'default', true, true);
            }


            if ($('div#sub_crumbs_holder li.active').length) {
                let scrollToId = $('div#sub_crumbs_holder li.active');
                let parentElementId = $('div#sub_crumbs_holder');
                scrollToActive(parentElementId, scrollToId, subScrollContent);
            }

            //Scroll on sub-categories is activated
            $("div#sub_crumbs_holder").on('scroll', function () {

                //Scroll is all to left
                if (subScrollWindow.scrollLeft() === 0) {
                    arrowInButton('left', 'default', true, true)
                }

                //Scroll is not all to the left
                if (subScrollWindow.scrollLeft() > 0) {
                    arrowInButton('left', 'pointer', false, true);
                }

                //Scroll is all to the right (15px is added because you cant get all to the right when you scroll with finger on mobile)
                if (subScrollWindow.scrollLeft() > subScrollContent.get(0).scrollWidth - subScrollWindow.outerWidth() - 15) {
                    arrowInButton('right', 'default', true, true);
                }

                //Scroll is not all to the right (15px is added because you cant get all to the right when you scroll with finger on mobile)
                if (subScrollWindow.scrollLeft() < subScrollContent.get(0).scrollWidth - subScrollWindow.outerWidth() - 15) {
                    arrowInButton('right', 'pointer', false, true);
                }
            });
        }

        showScrollWithInternetExplorer();

        //Scroll left if left scroll button is clicked
        $(document).on('click', 'div#crumbs_control_holder div#slide_left', function () {
            let pos = $('div#crumbs_holder').scrollLeft();
            $("div#crumbs_holder").animate({scrollLeft: pos - 150}, 400);
        });

        //Scroll right if right scroll button is clicked
        $(document).on('click', 'div#crumbs_control_holder div#slide_right', function () {
            let pos = $('div#crumbs_holder').scrollLeft();
            $("div#crumbs_holder").animate({scrollLeft: pos + 150}, 400);
        });

        //Scroll left if left scroll button is clicked in sub-menu
        $(document).on('click', 'div#sub_crumbs_control_holder div#sub_slide_left', function () {
            let pos = $('div#sub_crumbs_holder').scrollLeft();
            $("div#sub_crumbs_holder").animate({scrollLeft: pos - 150}, 400);
        });

        //Scroll right if right scroll button is clicked in sub-menu
        $(document).on('click', 'div#sub_crumbs_control_holder div#sub_slide_right', function () {
            let pos = $('div#sub_crumbs_holder').scrollLeft();
            $("div#sub_crumbs_holder").animate({scrollLeft: pos + 150}, 400);
        });


        //Draggable scroll -----------

        let clicked = false;
        let clickX = 0;
        let left = 0;

        //------Main menu

        $(document).on('mousedown', 'div#category_menu_list_wrapper div#crumbs_holder', function (e) {
            if (scrollContent.width() > scrollWindow.outerWidth()) {
                scrollWindow.addClass('grabbing');
                clicked = true;
                clickX = e.pageX;
                left = $(this).scrollLeft();
            }
        });

        $(document).on('mousemove', 'div#category_menu_list_wrapper div#crumbs_holder', function (e) {
            if (clicked === true) {
                updateScrollPos(e);
            }
        });

        $(document).on('mouseleave', 'div#category_menu_list_wrapper div#crumbs_holder', function () {
            clicked = false;
            scrollWindow.removeClass('grabbing');
            //scrollWindow.css('cursor', 'grab');
        });

        $(document).on('mouseup', 'div#category_menu_list_wrapper div#crumbs_holder', function () {
            clicked = false;
            scrollWindow.removeClass('grabbing');
            clickX = 0;
            left = 0;
        });


        //-----Sub menu

        $(document).on('mousedown', 'div#category_menu_list_wrapper div#sub_crumbs_holder', function (e) {
            if (subScrollContent.width() > subScrollWindow.outerWidth()) {
                subScrollWindow.addClass('grabbing');
                clicked = true;
                clickX = e.pageX;
                left = $(this).scrollLeft();
            }
        });

        $(document).on('mousemove', 'div#category_menu_list_wrapper div#sub_crumbs_holder', function (e) {
            updateScrollPos(e, true);
        });

        $(document).on('mouseleave', 'div#category_menu_list_wrapper div#sub_crumbs_holder', function () {
            clicked = false;
        });

        $(document).on('mouseup', 'div#category_menu_list_wrapper div#sub_crumbs_holder', function () {
            clicked = false;
            subScrollWindow.removeClass('grabbing');
            //scrollWindow.css('cursor', 'grab');
            clickX = 0;
            left = 0;
        });

        function updateScrollPos (e, subMenu = false) {
            if (clicked === true) {
                if (!subMenu) {
                    scrollWindow.scrollLeft(left + (clickX - e.pageX));
                } else {
                    subScrollWindow.scrollLeft(left + (clickX - e.pageX));
                }
            }
        }
    }


    //Scroll to the category that is active
    function scrollToActive(scrollElement, scrollToElement, scrollContent) {
        let right = scrollContent.get(0).scrollWidth - scrollElement.outerWidth();
        scrollElement.scrollLeft(right);
        scrollElement.animate({scrollLeft: scrollToElement.get(0).offsetLeft - (scrollElement.outerWidth() / 3)}, 'fast');
        //let leftOffset = scrollToElement.get(0).offsetLeft - (scrollElement.outerWidth() / 3);
        //scrollElement.scrollLeft(leftOffset);
    }

    function toggleFixed() {
        adjustFixedWidth();
        adjustFixedHeight();
        $('div#main-container').css('padding-top', menuHeight);
        $("div#category_menu_list_wrapper").css('position', 'fixed');
    }

    function adjustFixedHeight() {
        let headerElement = $('header.navbar');
        let headerHeight = headerElement.height();

        if (headerElement.css('position') === 'relative') {
            headerHeight = 0;
        }

        $("div#category_menu_list_wrapper").css('top', headerHeight);
    }

    function adjustFixedWidth() {
        let categoryMenu = $('div#category_menu_list_wrapper');
        let shopProductsColFrame = $(".shop-products-col-frame");

        if (shopProductsColFrame.length) {
            categoryMenu.css('width', shopProductsColFrame.outerWidth());
        } else {
            categoryMenu.css('width', categoryMenu.outerWidth());
        }
    }

    function removeFixed() {
        let categoryMenu = $('div#category_menu_list_wrapper');
        $('div#main-container').css('padding-top', '');
        categoryMenu.css('top', '');
        categoryMenu.css('width', '');
        categoryMenu.css('position', 'sticky');
    }

    function showScrollButtons(sub = false) {
        if (sub === false) {
            $('div#slide_right').show();
            $('div#slide_left').show();
        } else {
            $('div#sub_slide_right').show();
            $('div#sub_slide_left').show();
        }
    }

    function hideScrollButtons(sub = false) {
        if (sub === false) {
            $('div#slide_right').hide();
            $('div#slide_left').hide();
        } else {
            $('div#sub_slide_right').hide();
            $('div#sub_slide_left').hide();
        }
    }

    function arrowInButton(direction, cursorType, hide, sub = false) {
        let buttonElement = 'div#slide_' + direction;
        let glyphicon = 'span.glyphicon-chevron-' + direction;

        if (sub === true) {
            buttonElement = 'div#sub_slide_' + direction;
            glyphicon = 'span.glyphicon-chevron-' + direction;
        }

        if (hide === true) {
            $(buttonElement + ' ' + glyphicon).hide();
        } else {
            $(buttonElement + ' ' + glyphicon).show();
        }

        $(buttonElement).css('cursor', cursorType);
    }

    function showScrollWithInternetExplorer() {
        let isIE = browserIsInternetExplorer();

        if (isIE) {
            showScrollButtons();
            showScrollButtons(true);
            arrowInButton('left', 'pointer', false);
            arrowInButton('left', 'pointer', false);
            arrowInButton('left', 'pointer', false, true);
            arrowInButton('right', 'pointer', false, true);
        }
    }

    //Return true or false if browser is internet explorer or not
    function browserIsInternetExplorer() {
        let ua = window.navigator.userAgent;
        return /MSIE|Trident/.test(ua);
    }

});