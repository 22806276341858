$(document).ready(function() {

    const ajaxPathDisplaySearchResult = '/action/frontline-store-overview/display-search-result';
    const ajaxPathGetFrontlineStoreInfo = '/action/frontline-store-overview/display-get-frontline-store-info';
    const ajaxPathSetFrontlineStoreAvailability = '/action/frontline-store-overview/change-frontline-store-availability';
    let ajaxLocked = false;

    /*
     * Display or hide filter options
     */
    $('div#frontline_store_right_side_menu_wrapper span#filter_span_wrapper').click(function () {
        // Toggle the filters wrapper
        $('div#filter_options_wrapper').toggle();

        // Change class of the glyphicon (arrow right or down
        let arrow = $('span#filter_span_wrapper span.glyphicon');
        if ($(arrow).hasClass('glyphicon-chevron-right')) {
            // Switch to down arrow
            $(arrow).removeClass('glyphicon-chevron-right');
            $(arrow).addClass('glyphicon-chevron-down');
        } else {
            // Switch to down right
            $(arrow).removeClass('glyphicon-chevron-down');
            $(arrow).addClass('glyphicon-chevron-right');
        }
    });

    /*
	 * Search if search button was clicked
	 */
    $('button#frontline_store_overview_search_button').click(function(){
        refreshFrontlineStoreListItems();
    });

    /*
	 * Search if enter key in the search field
	 */
    $('div#frontline_store_right_side_menu_wrapper input#frontline_store_overview_search_input').keyup(function(e) {
        let key = e.which;
        let searchInput = $('input#frontline_store_overview_search_input');

        // Refresh list if enter was pressed or the search field is empty
        if (key === 13 || searchInput.val() === '') {
            refreshFrontlineStoreListItems();
        }
    });

    /*
     * Show the frontlineStore that was clicked on
     */
    $(document).on('click', 'div#frontline_store_right_side_menu_wrapper .list-group-item', function() {
        if($(this).is('li')) {
            return;
        }
        let frontlineStoreId = $(this).attr('id');
        getFrontlineStoreInfo(frontlineStoreId);
    });

    /*
     * Refresh list if checkbox is changed
     */
    $(document).on('click', 'div#frontline_store_right_side_menu_wrapper input[name="display_deactivated"]', function() {
        refreshFrontlineStoreListItems();
    });

    /*
     * Change status for the frontlineStore
     */
    $(document).on('click', 'div#frontline_store_info_wrapper button#btn_change_availability', function() {
        let frontlineStoreId = $('div#frontline_store_info_wrapper input#frontline_store_id').val();
        let currentStoreStatus = $('button#btn_change_availability').val();
        changeAvailability(frontlineStoreId, currentStoreStatus);
        refreshFrontlineStoreListItems();
    });

    function refreshFrontlineStoreListItems() {
        if (ajaxLocked === true) {
            return;
        }
        ajaxLocked = true;
        let displayDeactivated = 0;

        if ($('input[name="display_deactivated"]').is(':checked')) {
            displayDeactivated = 1;
        }

        let searchInput = $('input#frontline_store_overview_search_input').val();

        $.ajax({
            type: 'GET',
            url: ajaxPathDisplaySearchResult,
            data: {
                display_deactivated: displayDeactivated,
                search_string: searchInput
            },
            dataType: 'json'
        }).success(function (data) {
            $('div#frontline_store_list_wrapper').html(data.html);
        }).error(function (data) {
            modalTitle = 'Det oppstod en feil!';
            modalMessage = $.parseJSON(data.responseText).errors;
            modalAlertType = 'error';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        }).always(function() {
            ajaxLocked = false;
        });
    }

    function getFrontlineStoreInfo(frontlineStoreId) {
        if (ajaxLocked === true) {
            return;
        }
        ajaxLocked = true;

        $.ajax({
            type: 'GET',
            url: ajaxPathGetFrontlineStoreInfo,
            data: {
              frontline_store_id: frontlineStoreId
            },
            dataType: 'json'
        }).success(function (data) {
            $('div#frontline_store_info_wrapper').html(data.html);
        }).error(function (data) {
            modalTitle = 'Det oppstod en feil!';
            modalMessage = $.parseJSON(data.responseText).errors;
            modalAlertType = 'error';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        }).always(function() {
            ajaxLocked = false;
        });
    }

    function changeAvailability(frontlineStoreId, currentStoreStatus) {
        if (ajaxLocked === true) {
            return;
        }

        $.ajax({
            type: 'PUT',
            url: ajaxPathSetFrontlineStoreAvailability,
            data: {
                frontline_store_id: frontlineStoreId,
                current_frontline_store_status: currentStoreStatus
            },
            dataType: 'json'
        }).success(function (data) {
            $('div#frontline_store_info_wrapper div#button_availability').html(data.html);
        }).error(function (data) {
            modalTitle = 'Det oppstod en feil!';
            modalMessage = $.parseJSON(data.responseText).errors;
            modalAlertType = 'error';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        });
    }
});