$(document).ready(function () {

    $(document).on('click', 'form#frontline_store_pick_up_time_form input.unit-of-time', function () {
        renameInputGroupAddonAndChangePlaceholder($(this));
        toggleMaximumInput($(this));
    });

    $(document).on('change', 'form#frontline_store_pick_up_time_form div.minimum-time-wrapper input.minimum-time', function () {
        let unitOfTime = $(this).closest('div.frontline-store-form-group').find('input.unit-of-time:checked').val();
        if (unitOfTime === 'days') {
            addDaysToMaximumInput($(this));
        }
    });

    function renameInputGroupAddonAndChangePlaceholder(clickedInputElement) {
        let unitOfTimeAddon = clickedInputElement.closest('div.frontline-store-form-group').find('span.unit-of-time-addon');
        let input = clickedInputElement.closest('div.frontline-store-form-group').find('input:not(.unit-of-time)');
        if (clickedInputElement.val() === 'minutes') {
            input.attr('placeholder', 'minutter');
            unitOfTimeAddon.html('minutter');
        } else if (clickedInputElement.val() === 'days') {
            input.attr('placeholder', 'dager');
            unitOfTimeAddon.html('dager');
        }
    }

    // Hide the maximum time input when days is chosen. Show it when minutes is chosen.
    function toggleMaximumInput(clickedInputElement) {
        let maximumTimeWrapper = clickedInputElement.closest('div.frontline-store-form-group').find('div.maximum-time-wrapper');
        let label = clickedInputElement.closest('div.frontline-store-form-group').find('label.from-to-label');
        let unitOfTime = clickedInputElement.val();
        if (unitOfTime === 'minutes') {
            maximumTimeWrapper.css('visibility', 'visible');
            label.css('visibility', 'visible');
        } else if (unitOfTime === 'days') {
            maximumTimeWrapper.css('visibility', 'hidden');
            label.css('visibility', 'hidden');
        }
    }

    // When the minimum time is changed, add the minimum time to the maximum time input if day is selected as unit of time.
    function addDaysToMaximumInput(minimumTimeInputElement) {
        let dayValue = minimumTimeInputElement.val();
        console.log(dayValue);
        let maximumTimeInput = minimumTimeInputElement.closest('div.frontline-store-form-group').find('input.maximum-time');
        maximumTimeInput.val(dayValue);
    }


});