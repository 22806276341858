$(document).ready(function() {

    // Item name
    var itemName = 'produktkategori';

    // Lock to make sure user doesnt save two instances of the same form
    var ajaxLockActive = false;

    // Ajax path
    var ajaxPath = '/includes/productCategory/ajax/ajax.php';
    var ajaxPathSaveProductCategoryContent = '/action/product-category/save-product-category-content';
    var ajaxPathUpdateProductCategoryList = '/action/product-category/update-product-category-list';

    /*
     * Display or hide filter options
     */
    $(document).on('click', 'div#product_category_list_wrapper span#filter_span_wrapper', function() {
        // Toggle the filters wrapper
        $('div#filter_options_wrapper').toggle();

        // Change class of the glyphicon (arrow right or down
        let arrow = $('span#filter_span_wrapper span.glyphicon');
        if ($(arrow).hasClass('glyphicon-chevron-right')) {
            // Switch to down arrow
            $(arrow).removeClass('glyphicon-chevron-right');
            $(arrow).addClass('glyphicon-chevron-down');
        } else {
            // Switch to down right
            $(arrow).removeClass('glyphicon-chevron-down');
            $(arrow).addClass('glyphicon-chevron-right');
        }
    });

    /*
     * Show deactivated product categories
     */
    $(document).on('click', 'div#product_category_list_wrapper input#display_deactivated', function() {
        refreshProductCategoryListSearch();
    });


    /*
     * Save a ProductCategory
     */

    $(document).on('click', 'form#product_category_form button#save_product_category', function() {
        if (ajaxLockActive) {
            return;
        }
        $(this).html('<img src="/htdocs/images/loading.gif?v=1.0" style="width:20px; height: 20px;"/>  Lagrer');

        let productCategoryType = $('form#product_category_form input[name=product_category_type]').val();
        let productCategoryName = $('form#product_category_form input#name').val();
        let productCategoryDescription = $('form#product_category_form textarea#description').val();
        let productCategoryListOrder = $('form#product_category_form input[name=product_category_list_order]').val();
        let productCategoryParentId = $('form#product_category_form input[name=product_category_parent_product_category_id]').val();

        let formData = new FormData();

        if($('form#product_category_form input#product_category_id').val()) {
            let productCategoryId = $('form#product_category_form input#product_category_id').val();
            formData.append('product_category_id', productCategoryId);
        }

        if($('form#product_category_form select[name="select_consuming_location"]').val()) {
            let consumingLocation = $('form#product_category_form select[name="select_consuming_location"]').children("option:selected").val();
            formData.append('consuming_location', consumingLocation);
        }

        formData.append('product_category_type', productCategoryType);
        formData.append('product_category_name', productCategoryName);
        formData.append('product_category_description', productCategoryDescription);
        formData.append('product_category_list_order', productCategoryListOrder);
        formData.append('product_category_parent_product_category_id', productCategoryParentId);

        //Checkbox - Choose if product category should be visible in shop
        if ($('form#product_category_form input#select_visibility').is(":checked"))
        {
            formData.append('product_category_visible', '1');
        } else {
            formData.append('product_category_visible', '0');
        }

        $('form#product_category_form input[name^="production_open_from"]').each(function () {
            formData.append('production_open_from[]', $(this).val());
        });

        $('form#product_category_form input[name^="production_open_to"]').each(function () {
            formData.append('production_open_to[]', $(this).val());
        });

        if ($('div#form_group_sale_time_table').length) {
            $('form#product_category_form input[name^="sale_open_from"]').each(function () {
                formData.append('sale_open_from[]', $(this).val());
            });

            $('form#product_category_form input[name^="sale_open_to"]').each(function () {
                formData.append('sale_open_to[]', $(this).val());
            });
        }

        ajaxLockActive = true;

        $.ajax({
            type: 'POST',
            url: ajaxPathSaveProductCategoryContent,
            data: formData,
            processData: false,
            contentType: false,
            dataType: 'json'
        }).success(function (data) {
            //remove loading-gif
            $('button#save_product_category').html('Lagre');

            // Update list of ProductCategories
            refreshProductCategoryList();

            let removeInputs = true;
            if ($('input[name="product_category_id"]').val() * 1 > 0) {
                removeInputs = false;
            }

            // Insert success message
            displayAlertMessage('Lagret!', 'Trykk <a href="">her</a> for å lagre ny ' + itemName, 'success', removeInputs);
            $(window).scrollTop(0);

        }).error(function (data) {
            modalTitle = 'Det oppstod en feil!';
            modalMessage = $.parseJSON(data.responseText).errors;
            modalAlertType = 'error';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        }).always(function() {
            ajaxLockActive = false;
        });
    });

    
    /**
     * Deactivate or activate product category
     */
    $(document).on('click', 'form#product_category_form button#change_product_category_status', function() {
        if (ajaxLockActive) {
            return;
        }

        if ($(this).val() != 0 && $(this).val() != 1) {
            return;
        }

        if($(this).val() == 0) {
            if (!confirm('Vil du deaktivere denne kategorien? Den vil ikke lengre bli vist på forsiden til webshoppen.')) {
                return;
            }
        }

        let productCategoryId = $(this).attr('product_category_id');
        ajaxLockActive = true;
        $.ajax({
            type: 'POST',
            url: ajaxPath,
            data: {
                action: 'change_product_category_status',
                product_category_id: productCategoryId,
                status: $(this).val()
            }
        }).done(function(response) {
            // Validate response
            var success = true;
            if (response.status === undefined) {
                success = false;
            } else if (response.status.toUpperCase() !== 'OK') {
                success = false;
            }

            if (success) {
                // Success
                var removeInputs = true;

                // Insert success message
                displayAlertMessage('Lagret!', 'Trykk <a href="">her</a> for å lagre ny ' + itemName, 'success', removeInputs);

                // Update list of ProductCategories
                refreshProductCategoryList();
            } else {
                displayAlertMessage('Kunne ikke lagre!', response.error_message, 'danger', false);
            }
            ajaxLockActive = false;
        }, 'json');
    });

    /*
     * Fetch prefilled form to edit a ProductCategory
     */
    $(document).on('click', 'a.product_category_list_item', function(event) {
        //Verify that product_category_id is valid
        if (isNaN($(this).attr('product_category_id'))) {
            alert('Kunne ikke hente for endring, ugyldig ID');
        }

        //Fetch form with prefilled data for selected ProductCategory
        $.post(ajaxPath, {action: 'get_form', product_category_id: $(this).attr('product_category_id')}, function(response) {
            if (response.status.toUpperCase() === 'OK') {
                //Success, replace form
                $('form#product_category_form').replaceWith(response.html);
            } else {
                //Error, show error message
                displayAlertMessage('Kunne ikke lagre!', response.error_message, 'danger', false)
            }
        }, 'json');
        return false;
    });

    /*
    Display belonging panel
     */
    $(document).on('click', 'form#product_category_form button.accordion', function() {
        displayAccordionContent($(this));
    });

    /*
     * Search if enter key in the search field
     */
    $('input#product_category_search_input').keyup(function(e) {
        var key = e.which;
        var searchInput = $('input#product_category_search_input');

        // Refresh list if enter was pressed or the search field is empty
        if (key === 13 || searchInput.val() === '') {
            refreshProductCategoryListSearch();
        }
    });

    /*
     * Search if search button was clicked
     */
    $('button#product_category_search_button').click(function() {
        refreshProductCategoryListSearch();
    });

    /*
     * Refresh list of ProductCategorys after search
     */
    function refreshProductCategoryListSearch() {
        let displayDeactivated = false;
        if ($('div#product_category_list_wrapper input#display_deactivated').is(":checked"))
        {
            displayDeactivated = true;
        }

        $.ajax({
            type: 'POST',
            url: ajaxPath,
            data: {
                action: 'get_product_category_list',
                product_category_type: $('input[name="product_category_type"]').val(), 
                search_text: $('input#product_category_search_input').val(),
                display_deactivated: displayDeactivated
            }
        }).done(function(response) {
            if (response.status.toUpperCase() === 'OK') {
                //Success, replace list with new list
                $('div#product_category_list_wrapper div.list-group').replaceWith(response.html);
            } else {
                alert(response.error_message);
            }
        }, 'json');
    }

    function refreshProductCategoryList() {
        let displayDeactivated = false;
        if ($('div#product_category_list_wrapper input#display_deactivated').is(":checked"))
        {
            displayDeactivated = true;
        }
        $.ajax({
            type: 'GET',
            url: ajaxPathUpdateProductCategoryList,
            data: {
                product_category_type: $('input[name="product_category_type"]').val(),
                display_deactivated: displayDeactivated
            },
            dataType: 'json'
        }).success(function (data) {
            $('div#product_category_list_wrapper div.list-group').replaceWith(data.html);

            if(data.html === '') {
                location.reload();
            }

        }).error(function (data) {
            modalTitle = 'Det oppstod en feil!';
            modalMessage = $.parseJSON(data.responseText).errors;
            modalAlertType = 'error';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        });
    }



    /*
     * Display html message
     *
     * If legend exist in form, the message is appended after legend
     * If legend does not exist in form, the message is appended after fieldset
     * 
     * @param String headerText
     * @param String message
     * @param String type
     * @param bool removeInputs If true all input fields will be removed from form
     */
    function displayAlertMessage(headerText, message, type, removeInputs) {
        // Remove any existing alert message
        removeAlertMessage();

        // Build html
        var HTMLMessage = '<div class="alert alert-' + type + ' fade in" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>' +
                '<h4>' + headerText + '</h4>' +
                '<p>' + message + '</p>' +
                '</div>';

        // Add message below legend if it exist
        if ($('form#product_category_form legend').length) {
            $('form#product_category_form legend').after(HTMLMessage);
        } else {
            // Legend does not exist, add below fieldset
            $('form#product_category_form fieldset').prepend(HTMLMessage);
        }

        // Remove all input fields if saving new ProductCategory
        if (removeInputs) {
            $('form#product_category_form div.form-group').remove();
        }
        return;
    }

    /*
     * Remove bootstrap html message from form
     */
    function removeAlertMessage() {
        // Check if an alert message exist
        if ($('form#product_category_form div.alert').length) {
            // Remove alert div from form
            $('form#product_category_form div.alert').remove();
        }
        return;
    }

    /*
    Display belonging panel to accordion button
     */
    function displayAccordionContent(clickedElement) {
        clickedElement.addClass('active');
        let panel = clickedElement.next();

        if (panel.css('display') === 'block') {
            panel.hide();
            clickedElement.removeClass('active')
        } else {
            panel.show();
        }
    }

});