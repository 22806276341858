
$(document).ready(function() {
    const ajaxPathGetProductModal = '/action/product/get-product-modal';
    const ajaxPathSaveProductModalContent = '/action/product/save-product-modal-content';
    const ajaxPathGetProductCategorySelectListAndRemoveButtonHtml = '/action/product/get-product-category-select-list-html';
    const ajaxPathGetSearchResult = '/action/product/get-products-from-search-string';
    var ajaxLockActive = false;
    var modalParentElementInDOM = $('div#main-container');
    var modalTitle = '';
    var modalMessage = '';
    var modalButtonText = 'Lukk';
    var modalAlertType = '';
    let elementSelected = null;

    //Open products modal (not when click on td with class 'move-product')
    $(document).on('click', 'div#products_added_to_forder table#catering_products_wrapper tr.product_structure td:not(.move-product):not(button.bt-danger) ', function () {
        let productId = $(this).parent().attr('id');

        $.ajax({
            type: 'GET',
            url: ajaxPathGetProductModal,
            data: {
                product_id: productId
            },
            dataType: 'json'
        }).success(function (data) {
            if ($("div#products_modal_container"), $("div#main-container").length > 0) {
                $("div#products_modal_container").remove();
            }
            $('div#main-container').append('<div id="products_modal_container"></div>');
            $('div#products_modal_container').append(data.html);
            $("div#products_modal").modal('toggle');

        }).error(function (data) {
            modalTitle = 'Det oppstod en feil!';
            modalMessage = $.parseJSON(data.responseText).errors;
            modalAlertType = 'error';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        });
    });

    //Save products modal
    $(document).on('click', 'div#products_modal button#save_product_modal', function () {
        //Add loading gif to button
        $(this).html('<img src="/htdocs/images/loading.gif?v=1.0" style="width:20px; height: 20px;"/>  Lagrer');

        let productId = $('div#products_modal input#product_id').val();

        let formData = new FormData();
        formData.append('product_id', productId);

        if($('div#products_modal select[name="select_consuming_location"]').val()) {
            let consumingLocation = $('div#products_modal select[name="select_consuming_location"]').children("option:selected").val();
            formData.append('consuming_location', consumingLocation);
        }

        //Checkbox - Choose if product should be visible in shop
        if ($('div#products_modal input#select_visibility').is(":checked"))
        {
            formData.append('product_visible', '1');
        } else {
            formData.append('product_visible', '0');
        }

        $('div#products_modal select[name^="product_category_select"]').each(function () {
            if($(this).is(":visible")) {
                formData.append('product_category_select[]', $(this).children("option:selected").val());
            }
        });

        $('div#products_modal input[name^="production_open_from"]').each(function () {
            formData.append('production_open_from[]', $(this).val());
        });

        $('div#products_modal input[name^="production_open_to"]').each(function () {
            formData.append('production_open_to[]', $(this).val());
        });

        if ($('div#form_group_sale_time_table').length) {
            $('div#products_modal input[name^="sale_open_from"]').each(function () {
                formData.append('sale_open_from[]', $(this).val());
            });

            $('div#products_modal input[name^="sale_open_to"]').each(function () {
                formData.append('sale_open_to[]', $(this).val());
            });
        }

        formData.append('description',  $('div#products_modal textarea[name^="description"]').val());
        formData.append('email_recipients',  $('div#products_modal textarea[name^="email_recipients"]').val());

        const secondsFromHours = Number($('div#products_modal input[name^="production_time_hours"]').val()) * 3600;
        const secondsFromMinutes = Number($('div#products_modal input[name^="production_time_minutes"]').val()) * 60;
        const productionTimeInSeconds = secondsFromHours + secondsFromMinutes;
        formData.append('production_time', `${productionTimeInSeconds}`);

        const earliestDeliveryTime = `${$('div#products_modal select[name^="earliest_delivery_time_hour"]').val()}:${$('div#products_modal select[name^="earliest_delivery_time_minute"]').val()}`;
        const timeFormatRegex = /^([01]\d|2[0-3]):[0-5]\d$/;
        formData.append('earliest_delivery_time', timeFormatRegex.test(earliestDeliveryTime) ? earliestDeliveryTime : '');

        formData.append('produce_before_earliest_delivery_time',  $('div#products_modal input[name^="produce_before_earliest_delivery_time"]').is(':checked') ? '1' : '0');

        saveProduct(formData); 
    });

    //Remove a category
    $(document).on('click', 'div#products_modal .remove-category-button', function () {
        $(this).parent().parent().hide();
    });


    //Add a new category to a product
    $(document).on('click', 'div#products_modal button#add_new_category_product', function () {

        $.ajax({
            type: 'GET',
            url: ajaxPathGetProductCategorySelectListAndRemoveButtonHtml,
            data: {},
            dataType: 'json'
        }).success(function (data) {
            $('div#product_category_select_list_wrapper').append(data.html);

        }).error(function (data) {
            modalTitle = 'Det oppstod en feil!';
            modalMessage = $.parseJSON(data.responseText).errors;
            modalAlertType = 'error';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        });
    });

    // Product search on enter
    $(document).on('keyup', 'form#add_products_form input#product_search_input, ' +
        'form#add_product_to_customer_group_form input#product_search_input', function (e) {
        let searchResult = $('div#product_search_result');
        if ($(this).val().length < 2) {
            searchResult.empty();
            return;
        }

        if (e.keyCode === 38) { //key UP
            scrollListKeyUp();
        }
        else if (e.keyCode === 40) { //key DOWN
            scrollListKeyDown()

        }
        else if (e.keyCode === 13) { //key ENTER
            e.preventDefault();
            if (elementSelected != null) {
                setSelectedProduct(elementSelected);
            }
            return false;
        }

        else {
            let searchString = $(this).val();
            productSearch(searchString)
        }


        return false;
    });


    // Product search on click
    $(document).on('click', 'form#add_products_form button#product_search_button, ' +
        'form#add_product_to_customer_group_form button#product_search_button', function () {
        if ($(this).hasClass('no-result')) {
            return;
        }

        let searchString = $('form#add_products_form input#product_search_input').val();

        if (searchString.length > 1) {
            productSearch(searchString);
        }
    });


    $(document).on('click', 'form#add_products_form li.list-group-item:not(.selected-product), ' +
        'form#add_product_to_customer_group_form li.list-group-item:not(.selected-product)', function () {
        let productItem = $(this);
        setSelectedProduct(productItem);
    });

    $(document).on('click', 'form#add_products_form li.list-group-item span.close,' +
        'form#add_product_to_customer_group_form li.list-group-item span.close', function () {
        let hiddenProductId = '';
        if ($('form#add_products_form').length) {
            hiddenProductId = $('form#add_products_form input#product_id');
        }
        else if ($('form#add_product_to_customer_group_form').length) {
            hiddenProductId = $('form#add_product_to_customer_group_form input#product_id');
        }

        if (hiddenProductId !== '' && hiddenProductId.length) {
            hiddenProductId.remove();
        }
        let newSearch = printSearchWrapper();
        $('div#product_search_wrapper').html(newSearch);
    });

    $(document).on('mouseup', 'div#wrap', function (e) {
        let searchResult = $('div#product_search_result');
        let inputField = $('input#product_search_input');

        if(!searchResult.is(e.target) && !inputField.is(e.target) && searchResult.has(e.target).length === 0) {
            searchResult.empty();
        }
    });

    /*
    Display belonging panel
     */
    $(document).on('click', 'div#products_modal_container button.accordion', function() {
        displayAccordionContent($(this));
    });

    function saveProduct(formData) {
        if (ajaxLockActive === true) {
            return;
        }

        ajaxLockActive = true;
        $.ajax({
            type: 'POST',
            url: ajaxPathSaveProductModalContent,
            data: formData,
            processData: false,
            contentType: false,
            dataType: 'json'
        }).success(function (data) {
            //Remove loading gif
            $('button#save_product_modal').html('Lagre');


            let prependBeforeId = $('div#product_category_select_list_wrapper');
            let message = 'Varen ble lagret';
            let type = 'success';
            let parent = $('div#products_modal');
            prependToAlert(prependBeforeId, message, type, '', parent);

            $('div#products_modal').scrollTop(0);

            $(document).on('change', 'div#products_modal .modal-body', function () {
                if($('div#product_category_select_list_wrapper div#message').length) {
                    $('div#product_category_select_list_wrapper div#message').remove();
                }
            });

            $(document).on('click', 'div#products_modal .remove-category-button, div#products_modal button#add_new_category_product', function () {
                if($('div#product_category_select_list_wrapper div#message').length) {
                    $('div#product_category_select_list_wrapper div#message').remove();
                }
            });
        }).error(function (data) {
            modalTitle = 'Det oppstod en feil!';
            modalMessage = $.parseJSON(data.responseText).errors;
            modalAlertType = 'error';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        }).always(function () { 
            ajaxLockActive = false;
        });
    }

    function productSearch(searchString) {
        if (ajaxLockActive === true) {
            return;
        }
        //If 0 -> search in all products in frontline
        //If 1 -> search in products already added in fline
        let flineCategoryProducts = 0;

        if ($('form#add_product_to_customer_group_form').length) {
            flineCategoryProducts = 1;
        }

        ajaxLockActive = true;

        $.ajax({
            type: 'GET',
            url: ajaxPathGetSearchResult,
            data: {
                search_string: searchString,
                fline_category_products: flineCategoryProducts
            },
            dataType: 'json'
        }).success(function (data) {
            let searchResult = data.search_result;

            if ($('form#add_products_form').length) {
                $('form#add_products_form div#product_search_result').html(searchResult);
            }
            else if ($('form#add_product_to_customer_group_form').length) {
                $('form#add_product_to_customer_group_form div#product_search_result').html(searchResult);
            }

        }).error(function (data) {
            modalTitle = 'Det oppstod en feil!';
            modalMessage = $.parseJSON(data.responseText).errors;
            modalAlertType = 'error';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        }).always(function () {
            ajaxLockActive = false;
        });
    }

    function printSearchWrapper() {
        return '<input type="submit" disabled style="display: none" aria-hidden="true" />' +
            '<input autocomplete="off" type="text" class="form-control" id="product_search_input" placeholder="S&oslash;k">' +
            '<span class="input-group-btn">' +
            '<button class="btn btn-default" id="product_search_button" onclick="return false;">S&oslash;k</button>' +
            '</span>';
    }

    function scrollListKeyDown() {
        let liElements = $('div#product_search_result li.list-group-item');

        if(elementSelected && liElements.length) {
            elementSelected.removeClass('selected');
            let next = elementSelected.next();
            if(next.length > 0) {
                elementSelected = next.addClass('selected');
            } else {
                elementSelected = liElements.eq(0).addClass('selected');
            }
        } else {
            if (!(liElements.eq(0).hasClass('no-result'))) {
                elementSelected = liElements.eq(0).addClass('selected');
            }
        }
    }

    function scrollListKeyUp() {
        let liElements = $('div#product_search_result li.list-group-item');

        if(elementSelected && liElements.length) {
            elementSelected.removeClass('selected');
            let next = elementSelected.prev();
            if(next.length > 0) {
                elementSelected = next.addClass('selected');
            } else {
                elementSelected = liElements.last().addClass('selected');
            }
        } else {
            if (!(liElements.eq(0).hasClass('no-result'))) {
                elementSelected = liElements.last().addClass('selected');
            }
        }
    }

    function setSelectedProduct(productItem) {
        if (productItem.length && !productItem.hasClass('no-result')) {
            let selectedItemHtml = '<li class="list-group-item selected-product">' + productItem.html() + '<span class="close" title="Fjern vare og søk på nytt">&times;</span></li>';
            let hiddenInput = '<input type="hidden" name="product_id" id="product_id" value="' + productItem.attr('product_id') + '">';

            if ($('form#add_products_form').length) {
                $('form#add_products_form').append(hiddenInput);
            }
            else if($('form#add_product_to_customer_group_form')) {
                $('form#add_product_to_customer_group_form').append(hiddenInput);
            }

            $('div#product_search_wrapper').html(selectedItemHtml);
            $('div#product_search_result').empty();
            elementSelected = null;
        }
    }

    /*
    Display belonging panel to accordion button
     */
    function displayAccordionContent(clickedElement) {
        clickedElement.addClass('active');
        let panel = clickedElement.next();

        if (panel.css('display') === 'block') {
            panel.hide();
            clickedElement.removeClass('active')
        } else {
            panel.show();
        }
    }

});







