//Color picker documentation: https://seballot.github.io/spectrum/#toc0

$(document).ready(function () {
    const ajaxPathSaveColorsToFile = '/action/customer-settings/save-colors-to-file';
    const ajaxPathSetPreviewSession = '/action/customer-settings/set-preview-session';
    const ajaxPathUnsetPreviewSession = '/action/customer-settings/unset-preview-session';
    const ajaxPathDeleteColors = '/action/customer-settings/delete-colors';
    const ajaxPathSaveFonts = '/action/customer-settings/save-fonts';
    const ajaxPathDeleteFonts = '/action/customer-settings/delete-fonts';
    const ajaxPathCloseShop = '/action/customer-settings/close-shop';
    const ajaxPathSaveClosedShopCustomText = '/action/customer-settings/save-closed-shop-custom-text';
    const ajaxPathSaveText = '/action/customer-settings/save-text';
    const ajaxPathSaveLoginSettings = '/action/customer-settings/save-customer-settings';
    let modalParentElementInDOM = $('div#main-container');
    let modalTitle = '';
    let modalMessage = '';
    let modalButtonText = 'Lukk';
    let modalAlertType = '';
    let ajaxLock = false;

        $('[data-toggle="popover"]').popover({
            html: true,
        });

        if ($('div#color_settings_wrapper input.color-picker').length) {
            $(".color-picker").spectrum({
                type: "text",
                showInitial: true,
                showInput: true,
                locale: "fr"
            });
        }

        if ($('div#font_settings_wrapper input.font-picker').length) {
            $('div#font_settings_wrapper input.font-picker').fontselect();
        }

        toggleAmountSortExtraProductsFormGroup();
        toggleTipOptions();


    $(document).on('click', 'div#font_settings_wrapper button#save_fonts', function () {
        let font = $('div#font_settings_wrapper input.font-picker').val().replace(/\+/g, ' ');

        saveFonts(font);
    });

    $(document).on('click', 'div#font_settings_wrapper button#delete_fonts', function () {
        if (confirm("Er du sikker på at du vil slette egendefinert skrifttype? Standard skrifttype vil gjelde for nettsiden.")) {
            deleteFonts();
        }
        return false;
    });



    $(document).on('click', 'div#color_settings_wrapper button#delete_colors', function () {
        if (confirm("Er du sikker på at du vil slette dine egendefinerte farger? Standard farger vil gjelde for nettsiden.")) {
            deleteColors();
        }
        return false;

    });

    $(document).on('click', 'div#color_settings_wrapper button#save_colors', function () {
        let formData = new FormData();

        //append each color picker to a formData with ID (config_name) as key and color as value
        $('div#color_settings_wrapper .color-picker').each(function() {
            let color = $(this).spectrum("get");
            formData.append(($(this).attr('id')), color)
        });

        saveColors(formData);
    });

    $(document).on('click', 'div#layout_settings_wrapper button#save_layout_settings', function () {
        let formWrapperId = $(this).closest('div.form-horizontal').attr('id');
        saveCustomerSettings(formWrapperId);
    });

    $(document).on('click', 'div#general_settings_wrapper button#save_general_settings', function () {
        let formWrapperId = $(this).closest('div.form-horizontal').attr('id');
        saveCustomerSettings(formWrapperId);
    });

    $(document).on('click', 'div#login_settings_wrapper button#save_login_settings', function () {
        let formWrapperId = $(this).closest('div.form-horizontal').attr('id');
        saveCustomerSettings(formWrapperId);
    });

    $(document).on('click', 'div#production_order_columns button#save_production_order_settings', function () {
        saveProductionOrderCustomerSettings();
    });

    $(document).on('click', 'div#customer_settings_input_wrapper button#save_customer_settings_input_button', function () {
        saveCustomerSettingsInput();
    });

    $(document).on('click', 'div#color_settings_wrapper a#open_color_preview', function () {
        let formData = new FormData();

        //append each color picker to a formData with ID (config_name) as key and color as value
        $('div#color_settings_wrapper .color-picker').each(function() {
            let color = $(this).spectrum("get");
            formData.append(($(this).attr('id')), color)
        });

        setPreviewSession(formData);
    });

    $(document).on('click', 'div#font_settings_wrapper a#open_font_preview', function () {
        let formData = new FormData();

        //append font to formData
        let font = $('div#font_settings_wrapper input.font-picker').val().replace(/\+/g, ' ');
        formData.append('font', font);

        setPreviewSession(formData);
    });

    $(document).on('click', 'div#background_picture_wrapper a#open_background_image_preview', function () {
        let fileInput = $('input#background_image');
        if (fileInput[0].files.length === 0) {
            console.log('ingen fil');
            return;
            //alert("Vennligst velg et bilde før du åpner forhåndsvisning");
        }

        let formData = new FormData();

        //append file to formData
        let fileData = fileInput.prop('files')[0];
        formData.append('file', fileData);

        setPreviewSession(formData);
    });

    $(document).on('click', 'div.container div.form-horizontal a.unset_preview_session', function () {
        unsetPreviewSession();
    });


    //Hide or show form group where user decides the amount of extra products that is needed on a products before they are being categorized by product groups
    $(document).on('change', 'div#general_settings_wrapper input#extra_products_sort_by_product_groups', function () {
        toggleAmountSortExtraProductsFormGroup();
    });

    //Hide or show form group where user decides the amount of extra products that is needed on a products before they are being categorized by product groups
    $(document).on('change', 'div#general_settings_wrapper input#tip_active', function () {
        toggleTipOptions();
    });

    //Close or open shop
    $(document).on('click', 'div#close_shop_wrapper button#close_shop_button', function () {
        let closeShopValue = $('div#close_shop_wrapper input#close_shop').val();
        let confirmMessage = '';

        if (closeShopValue === '1') {
            confirmMessage = 'Er du sikker på at du vil stenge nettbutikken? Kunder kan ikke legge varer i handlekurven eller legge inn nye bestillinger. Du kan når som helst åpne igjen.';
        }
        else if (closeShopValue === '0') {
            confirmMessage = 'Er du sikker på at du vil åpne nettbutikken? Kunder vil da kunne legge varer i handlekurven og legge inn nye bestillinger. Du kan når som helst stenge igjen.';
        }
        else {
            return;
        }

        if (confirm(confirmMessage)) {
            closeOrOpenShop();
        }
    });

//Save custom text for closed shop
    $(document).on('click', 'div#close_shop_wrapper button#save_closed_shop_custom_text', function () {
        saveClosedShopCustomText();
    });

    function toggleAmountSortExtraProductsFormGroup() {
        let amountFormGroup = $('div#amount_sort_extra_products_form_group');
        let checkboxFormGroup = $('div#general_settings_wrapper input#extra_products_sort_by_product_groups');

        if (amountFormGroup.length) {
            if(checkboxFormGroup.is(':checked')) {
                amountFormGroup.show();
            } else {
                amountFormGroup.hide();
            }
        }
    }

    function toggleTipOptions() {
        let tipSettingsFormGroup = $('div#tip_settings_form_group');
        let checkbox = $('div#general_settings_wrapper input#tip_active');

        if (tipSettingsFormGroup.length) {
            if(checkbox.is(':checked')) {
                tipSettingsFormGroup.show();
            } else {
                tipSettingsFormGroup.hide();
            }
        }
    }

    function saveCustomerSettingsInput() {
        if (ajaxLock) {
            return;
        }
        let inputValue = $('div#customer_settings_input_wrapper input#customer_settings_input').val();
        let settingType = $('div#customer_settings_input_wrapper input#setting_type').val();

        ajaxLock = true;

        $.ajax({
            type: 'POST',
            url: ajaxPathSaveText,
            data: {
                'text': inputValue,
                'setting_type': settingType
            },
        }).success(function (data) {
            prependToAlert('div#customer_settings_input_wrapper', null, 'success', '<i class="far fa-check-circle"></i> Lagret!', 'div#main-container');

        }).error(function (data) {
            modalTitle = 'Det oppstod en feil';
            modalMessage = $.parseJSON(data.responseText).errors;
            modalAlertType = 'info';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        }).always(function () {
            ajaxLock = false;
        });
    }

    function saveFonts(font) {
        if (ajaxLock) {
            return;
        }

        ajaxLock = true;

        $.ajax({
            type: 'POST',
            url: ajaxPathSaveFonts,
            data: {
                font: font,
            },
        }).success(function (data) {
            prependToAlert('div#font_settings_wrapper', '', 'success', '<i class="far fa-check-circle"></i> Skrifttype ble lagret!', 'div#main-container');
            $(window).scrollTop(0);

        }).error(function (data) {
            modalTitle = 'Det oppstod en feil';
            modalMessage = $.parseJSON(data.responseText).errors;
            modalAlertType = 'error';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        }).always(function () {
            ajaxLock = false;
        });
    }

    function deleteFonts() {
        if (ajaxLock) {
            return;
        }

        ajaxLock = true;
        let customerSettingsId = $('div#font_settings_wrapper input#customer_settings_id').val();

        $.ajax({
            type: 'POST',
            url: ajaxPathDeleteFonts,
            data: {
                delete_font: true,
                customer_settings_id: customerSettingsId,
            },
        }).success(function (data) {
            prependToAlert('div#font_settings_wrapper', '', 'success', '<i class="far fa-check-circle"></i> Skrifttype ble slettet!', 'div#main-container');
            $(window).scrollTop(0);

            $('div#font_settings_wrapper div#delete_font_wrapper').hide();
            $('div#font_settings_wrapper div#current_font_wrapper').hide();

        }).error(function (data) {
            modalTitle = 'Det oppstod en feil';
            modalMessage = $.parseJSON(data.responseText).errors;
            modalAlertType = 'error';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        }).always(function () {
            ajaxLock = false;
        });
    }

    function saveColors(formData) {
        if (ajaxLock) {
            return;
        }

        ajaxLock = true;

        $.ajax({
            type: 'POST',
            url: ajaxPathSaveColorsToFile,
            data: formData,
            processData: false,
            contentType: false,
        }).success(function (data) {
            prependToAlert('div#color_settings_wrapper', '', 'success', '<i class="far fa-check-circle"></i> Egendefinerte farger ble lagret!', 'div#main-container');
            $(window).scrollTop(0);

        }).error(function (data) {
            modalTitle = 'Det oppstod en feil!';
            modalMessage = $.parseJSON(data.responseText).errors;
            modalAlertType = 'error';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        }).always(function () {
            ajaxLock = false;
        });
    }

    function setPreviewSession(formData) {
        if (ajaxLock) {
            return;
        }

        ajaxLock = true;
        $.ajax({
            type: 'POST',
            url: ajaxPathSetPreviewSession,
            data: formData,
            processData: false,
            contentType: false,
        }).success(function (data) {
            let win = window.open('/', '_blank');
            if (win) {
                //Browser has allowed it to be opened
                win.focus();
            } else {
                //Browser has blocked it
                alert('Please allow popups for this website');
            }

        }).error(function (data) {
            modalTitle = 'Det oppstod en feil!';
            modalMessage = $.parseJSON(data.responseText).errors;
            modalAlertType = 'error';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        }).always(function () {
            ajaxLock = false;
        });
    }

    function unsetPreviewSession() {
        if (ajaxLock) {
            return;
        }

        ajaxLock = true;
        $.ajax({
            type: 'POST',
            url: ajaxPathUnsetPreviewSession,
            data: {
                'unset_preview_session': true,
            },
        }).success(function (data) {
            location.reload();

        }).error(function (data) {
            modalTitle = 'Det oppstod en feil!';
            modalMessage = $.parseJSON(data.responseText).errors;
            modalAlertType = 'error';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        }).always(function () {
            ajaxLock = false;
        });
    }

    function deleteColors() {
        if (ajaxLock) {
            return;
        }

        ajaxLock = true;
        $.ajax({
            type: 'POST',
            url: ajaxPathDeleteColors,
            data: {
                'delete_colors': true,
            },
        }).success(function (data) {
            prependToAlert('div#color_settings_wrapper', '', 'success', '<i class="far fa-check-circle"></i> Dine farger ble slettet!', 'div#main-container');
            $(window).scrollTop(0);

            $('div#color_settings_wrapper .color-picker').each(function() {
                $(this).spectrum("set", "");

            });

            $('div#color_settings_wrapper div#delete_colors_wrapper').hide();

        }).error(function (data) {
            modalTitle = 'Det oppstod en feil!';
            modalMessage = $.parseJSON(data.responseText).errors;
            modalAlertType = 'error';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        }).always(function () {
            ajaxLock = false;
        });
    }

    function closeOrOpenShop() {
        if (ajaxLock) {
            return;
        }

        ajaxLock = true;

        let closedShop = $('div#close_shop_wrapper input#close_shop').val();

        $.ajax({
            type: 'POST',
            url: ajaxPathCloseShop,
            data: {
                'closed_shop': closedShop
            },
        }).success(function (data) {
            let closedShopCustomText = $('div#close_shop_wrapper input#closed_shop_custom_text').val();
            let closeShopButton = $('div#close_shop_wrapper button#close_shop_button');
            let alertTitle = '';
            let alertMessage = '';

            if (closedShop === '1') {
                closeShopButton.removeClass('shop-is-currently-open');
                closeShopButton.addClass('shop-is-currently-closed');
                closeShopButton.html('<i class="fas fa-lock-open"></i> Åpne salg');
                $('div#close_shop_wrapper input#close_shop').val(0);
                alertTitle = 'Salget ble stengt!';
                alertMessage = 'Kunder i nettbutikken får IKKE lov til å legge varer i handlekurven eller legge inn nye bestillinger. OBS: Må åpnes manuelt.';

            } else {
                closeShopButton.removeClass('shop-is-currently-closed');
                closeShopButton.addClass('shop-is-currently-open');
                closeShopButton.html('<i class="fas fa-lock"></i> Steng salg');
                $('div#close_shop_wrapper input#close_shop').val(1);
                alertTitle = 'Salget ble åpnet!';
                alertMessage = 'Kunder i nettbutikken kan nå legge varer i handlekurv og legge inn nye bestillinger igjen.';
            }

            // Add success alert after shop sale status has changed
            prependToAlert('div#stop_sale', alertMessage, 'success', '<i class="far fa-check-circle"></i> ' + alertTitle, 'div#customer_settings_wrapper div.tab-content');

        }).error(function (data) {
            modalTitle = 'Det oppstod en feil!';
            modalMessage = $.parseJSON(data.responseText).errors;
            modalAlertType = 'error';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        }).always(function () {
            ajaxLock = false;
        });
    }

    function saveClosedShopCustomText() {
        if (ajaxLock) {
            return;
        }

        ajaxLock = true;

        let closedShopCustomText = $('div#close_shop_wrapper input#closed_shop_custom_text').val();

        $.ajax({
            type: 'POST',
            url: ajaxPathSaveClosedShopCustomText,
            data: {
                'closed_shop_custom_text': closedShopCustomText
            },
        }).success(function (data) {
            prependToAlert('div#close_shop_wrapper', '', 'success', '<i class="far fa-check-circle"></i> Teksten ble lagret!', 'div#stop_sale');

        }).error(function (data) {
            modalTitle = 'Det oppstod en feil!';
            modalMessage = $.parseJSON(data.responseText).errors;
            modalAlertType = 'error';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        }).always(function () {
            ajaxLock = false;
        });
    }

    function saveCustomerSettings(formWrapperId) {
        if (ajaxLock ) {
            return;
        }

        let settingType = $('div#' + formWrapperId + ' input#setting_type').val();
        let customerSettings = [];

        // Loop through all elements and create a customer setting object for each
        $('div#' + formWrapperId + ' input[name="customer_settings[]"]').each(function() {
            let value = '';
            if ($(this).attr('type') === 'checkbox') {
                if ($(this).is(":checked")) {
                    value = '1';
                } else {
                    value = '0';
                }
            } else {
                value = $(this).val();
            }

            let customerSetting = {
                'name' : $(this).attr('id'),
                'value' : value,
                'setting_type' : settingType
            };
            customerSettings.push(customerSetting);
        });

        // Add value from radio buttons to add product_layout
        if (formWrapperId === 'layout_settings_wrapper' && $('div#product_layout_form_group').length) {
            let value = $("div#product_layout_form_group input[type='radio']:checked").val();
            customerSettings.push({
                'name' : 'product_layout',
                'value' : value,
                'setting_type' : settingType
            });
        }
        // Add value from radio buttons to add product_layout
        if (formWrapperId === 'layout_settings_wrapper' && $('div#restaurant_table_alternative_name_form_group').length) {
            let value = $("div#restaurant_table_alternative_name_form_group input[type='radio']:checked").val();
            customerSettings.push({
                'name' : 'restaurant_table_alternative_name',
                'value' : value,
                'setting_type' : settingType
            });
        }

        ajaxLock = true;
        $.ajax({
            type: 'POST',
            url: ajaxPathSaveLoginSettings,
            data: {
                'customer_settings': customerSettings
            },
        }).success(function (data) {
            prependToAlert('div#' + formWrapperId , null, 'success', '<i class="far fa-check-circle"></i> Lagret!', 'div#main-container');

        }).error(function (data) {
            modalTitle = 'Det oppstod en feil!';
            modalMessage = $.parseJSON(data.responseText).errors;
            modalAlertType = 'error';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        }).always(function () {
            ajaxLock = false;
        });
    }

    function saveProductionOrderCustomerSettings() {
        if (ajaxLock ) {
            return;
        }

        let settingType = $('div#production_order_settings_wrapper input[name="setting-type"]').val();
        const columns = [];
        const customerSettings = [];

        $('div#production_order_columns_settings_wrapper input.production_order_columns_input').each(function() {
            if ($(this).is(":checked")) {
                columns.push($(this).attr('name'));
            }
        });

        if(!columns.length) {
            modalTitle = 'Det oppstod en feil!';
            modalMessage = 'Du kan ikke ha en tabell uten kolonner';

            modalAlertType = 'error';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
            return;
        }

        $('div#production_order_settings_wrapper input[name="production_order_customer_settings[]"]').each(function() {
            let value = '';
            if ($(this).attr('type') === 'checkbox') {
                if ($(this).is(":checked")) {
                    value = '1';
                } else {
                    value = '0';
                }
            } else {
                value = $(this).val();
            }

            const customerSetting = {
                'name' : $(this).attr('id'),
                'value' : value,
                'setting_type' : settingType
            };
            customerSettings.push(customerSetting);
        });

        customerSettings.push({
            'name' : 'production_order_columns',
            'value' : JSON.stringify(columns),
            'setting_type' : settingType
        });

        ajaxLock = true;
        $.ajax({
            type: 'POST',
            url: ajaxPathSaveLoginSettings,
            data: {
                'customer_settings': customerSettings
            },
        }).success(function (data) {
            prependToAlert('div#production_order_settings_wrapper' , null, 'success', '<i class="far fa-check-circle"></i> Lagret!', 'div#main-container');

        }).error(function (data) {
            modalTitle = 'Det oppstod en feil!';
            modalMessage = $.parseJSON(data.responseText).errors;
            modalAlertType = 'error';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        }).always(function () {
            ajaxLock = false;
        });
    }

    $(document).on('change', 'div#production_order_columns_settings_wrapper #production_order_columns_select_all', function () {
        const value = $(this).is(":checked");
        $('div#production_order_columns_settings_wrapper input.production_order_columns_input').prop('checked', value);

        return false;
    });

    $(document).on('change', 'div#production_order_columns_settings_wrapper .production_order_columns_input', function () {
        const countCheckedColumns = $('div#production_order_columns_settings_wrapper .production_order_columns_input:checked').length;
        const countColumns = $('div#production_order_columns_settings_wrapper #production_order_columns_select_all').data('count-columns');

        const value = countCheckedColumns == countColumns;
        $('div#production_order_columns_settings_wrapper #production_order_columns_select_all').prop('checked', value);

        return false;
    });
});

