$(document).ready(function () {

    let ajaxPathEditConsumingLocation = '/action/shopping-cart/edit-consuming-location';
    let ajaxPathSetTipInShoppingCart = '/action/shopping-cart/set-tip';
    let modalParentElementInDOM = $('div#main-container');
    let modalTitle = '';
    let modalMessage = '';
    let modalButtonText = 'Lukk';
    let modalAlertType = '';

    $(document).on('click', 'div.shopping_cart_consuming_location span#edit_consuming_location', function () {

        let consumingLocation = $(this).attr('consuming_location');

        $.ajax({
            type: 'GET',
            url: ajaxPathEditConsumingLocation,
            data: {
                consuming_location: consumingLocation
            },
            dataType: 'json'
        }).success(function (data) {
            if ($('.shop-products-col-frame').length) {
                $('.shop-products-col-frame').replaceWith(data.html);
            } else if ($('div#shopping_cart_full').length) {
                displaySelectConsumingLocationModal(data.html);
            }

        }).error(function (data) {
            modalTitle = 'Det oppstod en feil!';
            modalMessage = $.parseJSON(data.responseText).errors;
            modalAlertType = 'error';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        });
    });

    $(document).on('click', "div#shopping_cart_full div.shopping_cart_tip button:not('.optional-tip-button'), div#shopping_cart_full button#add_custom_tip", function () {
        setTip($(this));
    });

    function displaySelectConsumingLocationModal(content) {
        if ($("div#select_consuming_location_modal_wrapper").length > 0) {
            $("div#select_consuming_location_modal_wrapper").remove();
        }

        $('body').append('<div id="select_consuming_location_modal_wrapper"></div>');

        $('div#select_consuming_location_modal_wrapper').append(
            '<div class="modal" id="select_consuming_location_modal" tabindex="-1" role="dialog">\n' +
            '  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">\n' +
            '    <div class="modal-content">\n' +
            '      <div class="modal-header">\n' +
            '         <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> &times;\n' +
            '          </button>\n' +
            '        <h4 class="modal-title">Takeaway eller spise her?</h4>\n' +
            '      </div>\n' +
            '      <div class="modal-body">\n' + content +
            '      </div>\n' +
            '      <div class="modal-footer">\n' +
            '        <button type="button" class="btn btn-default" data-dismiss="modal">Lukk</button>\n' +
            '      </div>\n' +
            '    </div>\n' +
            '  </div>\n' +
            '</div>');

        $("div#select_consuming_location_modal").modal("toggle");
    }

    function setTip(buttonClickedElement) {
        let formData = new FormData;
        if (buttonClickedElement.attr('id') === 'add_custom_tip') {
            let customTipAmount = $('div#shopping_cart_full div#custom_tip_modal input#custom_tip_amount').val();
            formData.append('tip_amount', parseFloat(customTipAmount.replace(',','.').replace(' ','')));
        } else {
            formData.append('tip_percent', buttonClickedElement.val());
        }

        $.ajax({
            type: 'POST',
            url: ajaxPathSetTipInShoppingCart,
            data: formData,
            processData: false,
            contentType: false,
            dataType: 'json'
        }).success(function (data) {
            location.reload();
        }).error(function (data) {
            modalTitle = 'Det oppstod en feil!';
            modalMessage = $.parseJSON(data.responseText).errors;
            modalAlertType = 'error';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        });
    }
});

