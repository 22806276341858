/**
 *
 * @param string message
 * @param string type
 * @param string title
 * @returns {string}
 */
function getAlertHtml(message, type, title) {

    var titleHtml = '';

    if (title != null && title !== '' && title !== undefined) {
        titleHtml = '<strong>' + title + '</strong><br><br>';
    }
    if ((message === '' || message === undefined || message === null) && (title != null || title !== '' || title !== undefined)) {
        titleHtml = '<strong>' + title + '</strong><br>';
        return '<div class="alert alert-' + type + ' alert-dismissible" id="message"><a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>' + titleHtml + '</div>';
    }
    else if ((title === '' || title === undefined || title === null) && (message !== '' || message !== undefined || message !== null)) {
        return '<div class="alert alert-' + type + ' alert-dismissible" id="message"><a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>' + message + '</div>';
    }

    return '<div class="alert alert-' + type + ' alert-dismissible" id="message"><a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>' + titleHtml + message + '</div>';

}

/**
 *
 * @param string message
 * @param string type
 * @param string title
 * @returns {string}
 */
function getAlertTable(message, type, title) {

    var titleHtml = '';

    if (title != null && title != '' && title !== undefined) {
        titleHtml = '<strong>' + title + '</strong><br><br>';
    }

    return '<tr><td colspan="3"><div class="alert alert-' + type + ' alert-dismissible" id="message"><a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>' + titleHtml + message + '</div></td></tr>';

}

/**
 *
 * @param string prependBeforeId
 * @param string message
 * @param string type
 * @param string title
 * @param string parent
 */
function prependToAlert(prependBeforeId, message, type, title, parent) {

    if (parent != null && parent != '' && parent !== undefined && $(parent).length > 0) {
        $(".alert", $(parent)).remove();
    }

    $(getAlertHtml(message, type, title)).prependTo($(prependBeforeId));

}

/**
 *
 * @param string appendAfterId
 * @param string message
 * @param string type
 * @param string title
 * @param string parent
 */
function appendToAlert(appendAfterId, message, type, title, parent) {

    if (parent != null && parent != '' && parent !== undefined && $(parent).length > 0) {
        $(".alert", $(parent)).remove();
    }

    $(getAlertHtml(message, type, title)).appendTo($(appendAfterId));
}

/**
 *
 * @param string insertAfterId
 * @param string message
 * @param string type
 * @param string title
 * @param string parent
 */
function insertAfterAlertTr(insertAfterId, message, type, title, parent) {

    if (parent != null && parent != '' && parent !== undefined && $(parent).length > 0) {
        $(".alert", $(parent)).remove();
    }

    $(getAlertTable(message, type, title)).insertAfter($(insertAfterId));

}

/**
 *
 * @param parentElementInDOM element in which modal is appended
 * @param title
 * @param message
 * @param buttonText
 * @param alertType error, success, info
 */
function displayInfoMessageModal(parentElementInDOM, title, message, buttonText, alertType) {
    if ($("div#info_message_modal_wrapper", parentElementInDOM).length > 0) {
        $("div#info_message_modal_wrapper").remove();
    }

    let modalClass = '';
    let glyphicon = '';
    switch (alertType) {
        case 'error':
            modalClass = 'error_modal';
            glyphicon = '<i class="far fa-times-circle"></i>';
            break;
        case 'success':
            modalClass = 'success_modal';
            glyphicon = '<i class="far fa-check-circle"></i>';
            break;
        case 'info':
            modalClass = 'info_modal';
            glyphicon = '<i class="fas fa-info-circle"></i>';
            break;
    }

    parentElementInDOM.append(
        '<div id="info_message_modal_wrapper">\n' +
        '<div class="modal" id="info_message_modal" tabindex="-1" role="dialog">\n' +
        '  <div class="modal-dialog modal-dialog-centered ' + modalClass + '" role="document">\n' +
        '    <div class="modal-content">\n' +
        '      <div class="modal-header">\n' +
        '        <h4 class="modal-title">' + glyphicon + '  ' + title + '</h4>\n' +
        '      </div>\n' +
        '      <div class="modal-body bs-callout bs-callout-' + alertType +'">\n' +
        '        <h5>' + message + '</h5>\n' +
        '      </div>\n' +
        '      <div class="modal-footer">\n' +
        '        <button type="button" class="btn btn-default" data-dismiss="modal">' + buttonText + '</button>\n' +
        '      </div>\n' +
        '    </div>\n' +
        '  </div>\n' +
        '</div>\n' +
        '</div>');

    /*
    let zIndexExtraProductsModal = $('div#extraProductsModal').css('z-index');
    let zIndex = Number(zIndexExtraProductsModal) + 1;
    $('div#info_message_modal').css('z-index', zIndex);

    setTimeout(function() {
        $('.modal-backdrop').not('.modal-stack').each(function() {
            $(this).css('z-index', zIndex - 2).addClass('modal-stack');
            zIndex++;
        });
    }, 0);*/

    $("div#info_message_modal").modal("toggle");

}

/**
 * @param {Element} table
 * @param {number} columnToSort
 * @param {string} sortMethod numeric, alphabetic, d/m/Y
 * @param excludeLastTr
 */
function sortTable(table, columnToSort, sortMethod, excludeLastTr = false) {
    var rows,
        switching,
        i,
        x,
        y,
        shouldSwitch,
        dir,
        switchcount = 0;
    switching = true;
    //Set the sorting direction to ascending:
    dir = "asc";

    let subtractFromRowsLength = 1;
    if (excludeLastTr === true) {
        subtractFromRowsLength = 2;
    }
    /*Make a loop that will continue until
    no switching has been done:*/
    while (switching) {
        //start by saying: no switching is done:
        switching = false;
        rows = table.find('> tbody > tr');
        /*Loop through all table rows (except the
        first, which contains table headers):*/
        for (i = 0; i < rows.length - subtractFromRowsLength; i++) {
            //start by saying there should be no switching:
            shouldSwitch = false;
            /*Get the two elements you want to compare,
            one from current row and one from the next:*/
            x = rows[i].getElementsByTagName("TD")[columnToSort];
            y = rows[i + 1].getElementsByTagName("TD")[columnToSort];
            /*check if the two rows should switch place,
            based on the direction, asc or desc:*/

            /*check if column content is numeric to cast the value to a number
             */
            let xValue = x.innerHTML.toLowerCase();
            let yValue = y.innerHTML.toLowerCase();
            if (sortMethod === 'numeric') {
                xValue = xValue.replace(',', '.') * 1;
                yValue = yValue.replace(',', '.') * 1;
            } else if (sortMethod == 'd/m/Y') {
                // month is 0-based, that's why we need dataParts[1] - 1
                let dateParts = xValue.split("/");
                xValue = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])

                dateParts = yValue.split("/");
                yValue = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
            }

            if (dir == "asc") {
                if (xValue > yValue) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            } else if (dir == "desc") {
                if (xValue < yValue) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }
        }
        if (shouldSwitch) {
            /*If a switch has been marked, make the switch
            and mark that a switch has been done:*/
            rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
            switching = true;
            //Each time a switch is done, increase this count by 1:
            switchcount++;
        } else {
            /*If no switching has been done AND the direction is "asc",
            set the direction to "desc" and run the while loop again.*/
            if (switchcount == 0 && dir == "asc") {
                dir = "desc";
                switching = true;
            }
        }
    }
}

function getProductsFromShoppingCart(shoppingCartElement) {
    let products = [];

    shoppingCartElement.each(function () {
        let productId = $(this).attr('product_id');
        products.push(productId);
    });

    return products;
}

function printAddedToShoppingCartBar() {
    let addedToShoppingCartBarElement = $('div#added_to_shopping_cart_bar');
    if (addedToShoppingCartBarElement.length) {
        addedToShoppingCartBarElement.show("fast").delay(4000).hide("slow");
    }
}

/**
 * The delay function will return a wrapped function that internally handles an individual timer,
 * in each execution the timer is restarted with the time delay provided,
 * if multiple executions occur before this time passes, the timer will just reset and start again.
 *
 * When the timer finally ends, the callback function is executed, passing the original context and arguments
 */
function delay(fn, ms) {
    let timer = 0
    return function(...args) {
        clearTimeout(timer)
        timer = setTimeout(fn.bind(this, ...args), ms || 0)
    }
}

/**
 * Notify.js is a jQuery plugin to provide simple yet fully customisable notifications.
 */
$.getScript("/htdocs/js/notify.min.js", function(){ });

/**
 * Notify when input is changed
 */
function inputNotify(success, element, text, position = 'left') {
    const className = success ? 'success' : 'error';
    $(element).notify(text, {
        position: position,
        className: className
    });
}